// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Col from "../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Grid from "../../../styleguide/components/Grid/Grid.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../bindings/Quill.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Editor from "../../../styleguide/forms/Editor/Editor.res.js";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as ResourceDocument from "./ResourceDocument.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceEditFormCss from "../resource-edit/ResourceEditFormCss.res.js";
import * as IconAdditionalUpload from "../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var validators_resourceCategoryId = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.resourceCategoryId;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Resource category is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.resourceCategoryId
              };
      }
    })
};

var validators_active = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.active;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Active is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.active
              };
      }
    })
};

var validators_popular = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.popular;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Popular is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.popular
              };
      }
    })
};

var validators_metaDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaDescription.length;
      if (length !== 0) {
        if (length < 50) {
          return {
                  TAG: "Error",
                  _0: "Meta description must be more than 50 characters."
                };
        } else if (length > 500) {
          return {
                  TAG: "Error",
                  _0: "Meta description must no more than 500 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.metaDescription
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Meta description is required"
              };
      }
    })
};

var validators_metaTitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaTitle.length;
      if (length !== 0) {
        if (length < 40) {
          return {
                  TAG: "Error",
                  _0: "Meta title must be more than 40 characters."
                };
        } else if (length > 200) {
          return {
                  TAG: "Error",
                  _0: "Meta title must no more than 200 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.metaTitle
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Meta title is required"
              };
      }
    })
};

var validators_resourceSummary = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.resourceSummary.length;
      if (length !== 0) {
        if (length < 50) {
          return {
                  TAG: "Error",
                  _0: "Summary must be more than 50 characters."
                };
        } else if (length > 200) {
          return {
                  TAG: "Error",
                  _0: "Summary must be no more than 200 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.resourceSummary
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Resource summary is required"
              };
      }
    })
};

var validators_title = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.title.length;
      if (length !== 0) {
        if (length < 20) {
          return {
                  TAG: "Error",
                  _0: "Title must be more than 20 characters."
                };
        } else if (length > 100) {
          return {
                  TAG: "Error",
                  _0: "Title must be no more than 100 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.title
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Title is required."
              };
      }
    })
};

var validators = {
  resourceCategoryId: validators_resourceCategoryId,
  active: validators_active,
  popular: validators_popular,
  metaDescription: validators_metaDescription,
  metaTitle: validators_metaTitle,
  resourceSummary: validators_resourceSummary,
  title: validators_title
};

function initialFieldsStatuses(_input) {
  return {
          resourceCategoryId: "Pristine",
          active: "Pristine",
          popular: "Pristine",
          metaDescription: "Pristine",
          metaTitle: "Pristine",
          resourceSummary: "Pristine",
          title: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.resourceCategoryId;
  var tmp;
  tmp = typeof match !== "object" ? validators.resourceCategoryId.validate(input) : match._0;
  var match$1 = fieldsStatuses.active;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.active.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.popular;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.popular.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.metaDescription;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.metaDescription.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.metaTitle;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.metaTitle.validate(input) : match$4._0;
  var match$5 = fieldsStatuses.resourceSummary;
  var tmp$5;
  tmp$5 = typeof match$5 !== "object" ? validators.resourceSummary.validate(input) : match$5._0;
  var match$6 = fieldsStatuses.title;
  var tmp$6;
  tmp$6 = typeof match$6 !== "object" ? validators.title.validate(input) : match$6._0;
  var resourceCategoryIdResult = tmp;
  var resourceCategoryIdResult$1;
  if (resourceCategoryIdResult.TAG === "Ok") {
    var activeResult = tmp$1;
    if (activeResult.TAG === "Ok") {
      var popularResult = tmp$2;
      if (popularResult.TAG === "Ok") {
        var metaDescriptionResult = tmp$3;
        if (metaDescriptionResult.TAG === "Ok") {
          var metaTitleResult = tmp$4;
          if (metaTitleResult.TAG === "Ok") {
            var resourceSummaryResult = tmp$5;
            if (resourceSummaryResult.TAG === "Ok") {
              var titleResult = tmp$6;
              if (titleResult.TAG === "Ok") {
                return {
                        TAG: "Valid",
                        output: {
                          title: titleResult._0,
                          resourceSummary: resourceSummaryResult._0,
                          metaTitle: metaTitleResult._0,
                          metaDescription: metaDescriptionResult._0,
                          popular: popularResult._0,
                          active: activeResult._0,
                          resourceCategoryId: resourceCategoryIdResult._0
                        },
                        fieldsStatuses: {
                          resourceCategoryId: {
                            TAG: "Dirty",
                            _0: resourceCategoryIdResult,
                            _1: "Shown"
                          },
                          active: {
                            TAG: "Dirty",
                            _0: activeResult,
                            _1: "Shown"
                          },
                          popular: {
                            TAG: "Dirty",
                            _0: popularResult,
                            _1: "Shown"
                          },
                          metaDescription: {
                            TAG: "Dirty",
                            _0: metaDescriptionResult,
                            _1: "Shown"
                          },
                          metaTitle: {
                            TAG: "Dirty",
                            _0: metaTitleResult,
                            _1: "Shown"
                          },
                          resourceSummary: {
                            TAG: "Dirty",
                            _0: resourceSummaryResult,
                            _1: "Shown"
                          },
                          title: {
                            TAG: "Dirty",
                            _0: titleResult,
                            _1: "Shown"
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              resourceCategoryIdResult$1 = resourceCategoryIdResult;
            } else {
              resourceCategoryIdResult$1 = resourceCategoryIdResult;
            }
          } else {
            resourceCategoryIdResult$1 = resourceCategoryIdResult;
          }
        } else {
          resourceCategoryIdResult$1 = resourceCategoryIdResult;
        }
      } else {
        resourceCategoryIdResult$1 = resourceCategoryIdResult;
      }
    } else {
      resourceCategoryIdResult$1 = resourceCategoryIdResult;
    }
  } else {
    resourceCategoryIdResult$1 = resourceCategoryIdResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            resourceCategoryId: {
              TAG: "Dirty",
              _0: resourceCategoryIdResult$1,
              _1: "Shown"
            },
            active: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            popular: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            metaTitle: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            },
            resourceSummary: {
              TAG: "Dirty",
              _0: tmp$5,
              _1: "Shown"
            },
            title: {
              TAG: "Dirty",
              _0: tmp$6,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurResourceCategoryIdField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.resourceCategoryId, validators_resourceCategoryId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: status,
                                  active: init.active,
                                  popular: init.popular,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  resourceSummary: init.resourceSummary,
                                  title: init.title
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.active, validators_active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: init.resourceCategoryId,
                                  active: status,
                                  popular: init.popular,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  resourceSummary: init.resourceSummary,
                                  title: init.title
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurPopularField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.popular, validators_popular, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: init.resourceCategoryId,
                                  active: init.active,
                                  popular: status,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  resourceSummary: init.resourceSummary,
                                  title: init.title
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaDescription, validators_metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: init.resourceCategoryId,
                                  active: init.active,
                                  popular: init.popular,
                                  metaDescription: status,
                                  metaTitle: init.metaTitle,
                                  resourceSummary: init.resourceSummary,
                                  title: init.title
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaTitleField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaTitle, validators_metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: init.resourceCategoryId,
                                  active: init.active,
                                  popular: init.popular,
                                  metaDescription: init.metaDescription,
                                  metaTitle: status,
                                  resourceSummary: init.resourceSummary,
                                  title: init.title
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurResourceSummaryField" :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.resourceSummary, validators_resourceSummary, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: init.resourceCategoryId,
                                  active: init.active,
                                  popular: init.popular,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  resourceSummary: status,
                                  title: init.title
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurTitleField" :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  resourceCategoryId: init.resourceCategoryId,
                                  active: init.active,
                                  popular: init.popular,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle,
                                  resourceSummary: init.resourceSummary,
                                  title: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateResourceCategoryIdField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.resourceCategoryId, state.submissionStatus, validators_resourceCategoryId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: status,
                                            active: init.active,
                                            popular: init.popular,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            resourceSummary: init.resourceSummary,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.active, state.submissionStatus, validators_active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: init.resourceCategoryId,
                                            active: status,
                                            popular: init.popular,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            resourceSummary: init.resourceSummary,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdatePopularField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.popular, state.submissionStatus, validators_popular, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: init.resourceCategoryId,
                                            active: init.active,
                                            popular: status,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            resourceSummary: init.resourceSummary,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.metaDescription, state.submissionStatus, validators_metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: init.resourceCategoryId,
                                            active: init.active,
                                            popular: init.popular,
                                            metaDescription: status,
                                            metaTitle: init.metaTitle,
                                            resourceSummary: init.resourceSummary,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaTitleField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.metaTitle, state.submissionStatus, validators_metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: init.resourceCategoryId,
                                            active: init.active,
                                            popular: init.popular,
                                            metaDescription: init.metaDescription,
                                            metaTitle: status,
                                            resourceSummary: init.resourceSummary,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateResourceSummaryField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.resourceSummary, state.submissionStatus, validators_resourceSummary, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: init.resourceCategoryId,
                                            active: init.active,
                                            popular: init.popular,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            resourceSummary: status,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateTitleField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            resourceCategoryId: init.resourceCategoryId,
                                            active: init.active,
                                            popular: init.popular,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle,
                                            resourceSummary: init.resourceSummary,
                                            title: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateResourceCategoryId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateResourceCategoryIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updatePopular: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePopularField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateResourceSummary: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateResourceSummaryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurResourceCategoryId: (function () {
              dispatch("BlurResourceCategoryIdField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          blurPopular: (function () {
              dispatch("BlurPopularField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          blurResourceSummary: (function () {
              dispatch("BlurResourceSummaryField");
            }),
          blurTitle: (function () {
              dispatch("BlurTitleField");
            }),
          resourceCategoryIdResult: Formality.exposeFieldResult(state.fieldsStatuses.resourceCategoryId),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          popularResult: Formality.exposeFieldResult(state.fieldsStatuses.popular),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          resourceSummaryResult: Formality.exposeFieldResult(state.fieldsStatuses.resourceSummary),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.resourceCategoryId;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.active;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.popular;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.metaDescription;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.metaTitle;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.resourceSummary;
              if (typeof tmp$5 === "object") {
                return true;
              }
              var tmp$6 = match.title;
              if (typeof tmp$6 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ResourceForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function reducer(state, action) {
  if (action.TAG === "SetDocument") {
    return {
            document: action._0,
            editorState: state.editorState
          };
  } else {
    return {
            document: state.document,
            editorState: Editor.State.compose(state.editorState, action._0)
          };
  }
}

function ResourceNewForm(props) {
  var categories = props.categories;
  var initialState_editorState = Editor.State.make();
  var initialState = {
    document: undefined,
    editorState: initialState_editorState
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var initialInput = React.useMemo((function () {
          var category = Belt_Array.get(categories, 0);
          return {
                  title: "",
                  resourceSummary: "",
                  metaTitle: "",
                  metaDescription: "",
                  popular: "False",
                  active: "False",
                  resourceCategoryId: category !== undefined ? ID.toString(category.id) : "1"
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api.createNewResource(output.title, output.resourceSummary, document.querySelector(".ql-editor").innerHTML, output.metaTitle, output.metaDescription, Belt_Option.getExn(state.document), output.active, output.popular, output.resourceCategoryId), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ResourceNewForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ResourceNewForm.make"
                              }, "CreateNewResource::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  var resource = x._0;
                  Url.visit(Routes_Resource.show(resource.slug, resource.categorySlug));
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.resourceToolbarOptions, {}, undefined, container);
                    })));
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  var match$1 = form.resourceSummaryResult;
  var tmp;
  tmp = match$1 !== undefined && match$1.TAG !== "Ok" ? JsxRuntime.jsx(ErrorMessage.make, {
          children: match$1._0
        }) : null;
  var match$2 = state.document;
  var match$3 = form.status;
  var tmp$1;
  tmp$1 = typeof match$3 !== "object" || match$3.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        children: "Something went wrong."
                      })
                })
          ]
        });
  var match$4 = state.document;
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Grid.make, {
                    className: ResourceEditFormCss.marginGrid,
                    children: [
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--title",
                                          children: "Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "resource-form--title",
                                            value: form.input.title,
                                            placeholder: "Add a title for your new resource.",
                                            onChange: (function ($$event) {
                                                form.updateTitle((function (input, value) {
                                                        return {
                                                                title: value,
                                                                resourceSummary: input.resourceSummary,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                popular: input.popular,
                                                                active: input.active,
                                                                resourceCategoryId: input.resourceCategoryId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.titleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--resource-summary",
                                          children: "Summary"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "resource-form--resource-summary",
                                            value: form.input.resourceSummary,
                                            placeholder: "Add a summary for your resource.",
                                            onChange: (function ($$event) {
                                                form.updateResourceSummary((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                resourceSummary: value,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                popular: input.popular,
                                                                active: input.active,
                                                                resourceCategoryId: input.resourceCategoryId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      tmp
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--resource-detail",
                                          children: "Details"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Editor.make, {
                                                ref: containerRef
                                              }),
                                          className: ResourceEditFormCss.editor
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--meta-title",
                                          children: "Meta Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "resource-form--meta-title",
                                            value: form.input.metaTitle,
                                            placeholder: "Add a meta title.",
                                            onChange: (function ($$event) {
                                                form.updateMetaTitle((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                resourceSummary: input.resourceSummary,
                                                                metaTitle: value,
                                                                metaDescription: input.metaDescription,
                                                                popular: input.popular,
                                                                active: input.active,
                                                                resourceCategoryId: input.resourceCategoryId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaTitleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--meta-description",
                                          children: "Meta Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "resource-form--meta-description",
                                            value: form.input.metaDescription,
                                            placeholder: "Add a meta description.",
                                            onChange: (function ($$event) {
                                                form.updateMetaDescription((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                resourceSummary: input.resourceSummary,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: value,
                                                                popular: input.popular,
                                                                active: input.active,
                                                                resourceCategoryId: input.resourceCategoryId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaDescriptionResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--pdf-file",
                                          children: "PDF File"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(ResourceDocument.make, {
                                            document: state.document,
                                            removeDocument: (function (param) {
                                                dispatch({
                                                      TAG: "SetDocument",
                                                      _0: undefined
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(Uploader.make, {
                                            allowed: ["Pdf"],
                                            multiple: false,
                                            upload: (function (files) {
                                                Belt_Array.map(files, (function (file) {
                                                        var match = Uploader.FileType.fromMime(file.type);
                                                        if (match === "Pdf") {
                                                          return dispatch({
                                                                      TAG: "SetDocument",
                                                                      _0: Caml_option.some(file)
                                                                    });
                                                        }
                                                        
                                                      }));
                                              }),
                                            setUploadHandler: (function (prim) {
                                                
                                              }),
                                            children: match$2 !== undefined ? null : JsxRuntime.jsxs(Button.make, {
                                                    size: "SM",
                                                    color: "Teal",
                                                    buttonClassName: ResourceEditFormCss.uploadDocumentButton,
                                                    children: [
                                                      JsxRuntime.jsx(IconAdditionalUpload.make, {
                                                            size: "MD",
                                                            color: "White"
                                                          }),
                                                      "Upload Document"
                                                    ]
                                                  })
                                          })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--popular",
                                          children: "Popular"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsxs(Select.make, {
                                          id: "resource-form--popular",
                                          value: form.input.popular,
                                          onChange: (function ($$event) {
                                              form.updatePopular((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              resourceSummary: input.resourceSummary,
                                                              metaTitle: input.metaTitle,
                                                              metaDescription: input.metaDescription,
                                                              popular: value,
                                                              active: input.active,
                                                              resourceCategoryId: input.resourceCategoryId
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "false",
                                                  children: "False"
                                                }, "popular-false"),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "true",
                                                  children: "True"
                                                }, "popular-true")
                                          ]
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--active",
                                          children: "Active"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsxs(Select.make, {
                                          id: "resource-form--active",
                                          value: form.input.active,
                                          onChange: (function ($$event) {
                                              form.updateActive((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              resourceSummary: input.resourceSummary,
                                                              metaTitle: input.metaTitle,
                                                              metaDescription: input.metaDescription,
                                                              popular: input.popular,
                                                              active: value,
                                                              resourceCategoryId: input.resourceCategoryId
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "false",
                                                  children: "False"
                                                }, "resource-form--active-false"),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "true",
                                                  children: "True"
                                                }, "resource-form--active-true")
                                          ]
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: ResourceEditFormCss.marginRow,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "resource-form--resource-category-id",
                                          children: "Category"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.flexContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx(Select.make, {
                                          id: "resource-form--category",
                                          value: form.input.resourceCategoryId,
                                          onChange: (function ($$event) {
                                              form.updateActive((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              resourceSummary: input.resourceSummary,
                                                              metaTitle: input.metaTitle,
                                                              metaDescription: input.metaDescription,
                                                              popular: input.popular,
                                                              active: input.active,
                                                              resourceCategoryId: value
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: Belt_Array.map(categories, (function (category) {
                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                              value: ID.toString(category.id),
                                                              children: category.title
                                                            }, "resource-form--category-" + ID.toString(category.id));
                                                }))
                                        })
                                  })
                            ]
                          }),
                      tmp$1,
                      JsxRuntime.jsxs(Row.make, {
                            className: "row",
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx("div", {})
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: ResourceEditFormCss.buttonCreateContainer,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "Primary",
                                          expanded: true,
                                          disabled: form.submitting,
                                          visuallyDisabled: match$4 === undefined,
                                          busy: form.submitting,
                                          submit: true,
                                          children: "Create Resource"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    md: 5,
                                    lg: 5,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "White",
                                          expanded: true,
                                          busy: false,
                                          submit: true,
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Url.visit(Routes_Resource.Dashboard.index);
                                            }),
                                          children: "Cancel"
                                        })
                                  })
                            ]
                          })
                    ]
                  }),
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var Css;

var make = ResourceNewForm;

export {
  Css ,
  ResourceForm ,
  reducer ,
  make ,
}
/* ID Not a pure module */
